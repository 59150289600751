import { default as pages_47_91_46_46_46permalink_93_46vueMeta } from "/vercel/path0/pages/[...permalink].vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/[status].vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index/followers.vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index/following.vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index/media.vue?macro=true";
import { default as pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta } from "/vercel/path0/pages/[[server]]/@[account]/index/with_replies.vue?macro=true";
import { default as pages_47_91_91server_93_93_47explore_46vueMeta } from "/vercel/path0/pages/[[server]]/explore.vue?macro=true";
import { default as pages_47_91_91server_93_93_47explore_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/explore/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47explore_47links_46vueMeta } from "/vercel/path0/pages/[[server]]/explore/links.vue?macro=true";
import { default as pages_47_91_91server_93_93_47explore_47tags_46vueMeta } from "/vercel/path0/pages/[[server]]/explore/tags.vue?macro=true";
import { default as pages_47_91_91server_93_93_47explore_47users_46vueMeta } from "/vercel/path0/pages/[[server]]/explore/users.vue?macro=true";
import { default as pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/list/[list]/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta } from "/vercel/path0/pages/[[server]]/list/[list]/index/accounts.vue?macro=true";
import { default as pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/list/[list]/index/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47lists_46vueMeta } from "/vercel/path0/pages/[[server]]/lists.vue?macro=true";
import { default as pages_47_91_91server_93_93_47public_47index_46vueMeta } from "/vercel/path0/pages/[[server]]/public/index.vue?macro=true";
import { default as pages_47_91_91server_93_93_47public_47local_46vueMeta } from "/vercel/path0/pages/[[server]]/public/local.vue?macro=true";
import { default as pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta } from "/vercel/path0/pages/[[server]]/status/[status].vue?macro=true";
import { default as pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta } from "/vercel/path0/pages/[[server]]/tags/[tag].vue?macro=true";
import { default as pages_47blocks_46vueMeta } from "/vercel/path0/pages/blocks.vue?macro=true";
import { default as pages_47bookmarks_46vueMeta } from "/vercel/path0/pages/bookmarks.vue?macro=true";
import { default as pages_47compose_46vueMeta } from "/vercel/path0/pages/compose.vue?macro=true";
import { default as pages_47conversations_46vueMeta } from "/vercel/path0/pages/conversations.vue?macro=true";
import { default as pages_47domain_blocks_46vueMeta } from "/vercel/path0/pages/domain_blocks.vue?macro=true";
import { default as pages_47favourites_46vueMeta } from "/vercel/path0/pages/favourites.vue?macro=true";
import { default as pages_47home_46vueMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as pages_47index_46vueMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as pages_47intent_47post_46vueMeta } from "/vercel/path0/pages/intent/post.vue?macro=true";
import { default as pages_47mutes_46vueMeta } from "/vercel/path0/pages/mutes.vue?macro=true";
import { default as pages_47notifications_46vueMeta } from "/vercel/path0/pages/notifications.vue?macro=true";
import { default as pages_47notifications_47index_46vueMeta } from "/vercel/path0/pages/notifications/index.vue?macro=true";
import { default as pages_47notifications_47mention_46vueMeta } from "/vercel/path0/pages/notifications/mention.vue?macro=true";
import { default as pages_47pinned_46vueMeta } from "/vercel/path0/pages/pinned.vue?macro=true";
import { default as pages_47settings_46vueMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as pages_47settings_47about_47index_46vueMeta } from "/vercel/path0/pages/settings/about/index.vue?macro=true";
import { default as pages_47settings_47index_46vueMeta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as pages_47settings_47interface_47index_46vueMeta } from "/vercel/path0/pages/settings/interface/index.vue?macro=true";
import { default as pages_47settings_47language_47index_46vueMeta } from "/vercel/path0/pages/settings/language/index.vue?macro=true";
import { default as pages_47settings_47notifications_47index_46vueMeta } from "/vercel/path0/pages/settings/notifications/index.vue?macro=true";
import { default as pages_47settings_47notifications_47notifications_46vueMeta } from "/vercel/path0/pages/settings/notifications/notifications.vue?macro=true";
import { default as pages_47settings_47notifications_47push_45notifications_46vueMeta } from "/vercel/path0/pages/settings/notifications/push-notifications.vue?macro=true";
import { default as pages_47settings_47preferences_47index_46vueMeta } from "/vercel/path0/pages/settings/preferences/index.vue?macro=true";
import { default as pages_47settings_47profile_47appearance_46vueMeta } from "/vercel/path0/pages/settings/profile/appearance.vue?macro=true";
import { default as pages_47settings_47profile_47featured_45tags_46vueMeta } from "/vercel/path0/pages/settings/profile/featured-tags.vue?macro=true";
import { default as pages_47settings_47profile_47index_46vueMeta } from "/vercel/path0/pages/settings/profile/index.vue?macro=true";
import { default as pages_47settings_47users_47index_46vueMeta } from "/vercel/path0/pages/settings/users/index.vue?macro=true";
import { default as pages_47share_45target_46vueMeta } from "/vercel/path0/pages/share-target.vue?macro=true";
export default [
  {
    name: pages_47_91_46_46_46permalink_93_46vueMeta?.name ?? "permalink",
    path: pages_47_91_46_46_46permalink_93_46vueMeta?.path ?? "/:permalink(.*)*",
    children: [],
    meta: pages_47_91_46_46_46permalink_93_46vueMeta,
    alias: pages_47_91_46_46_46permalink_93_46vueMeta?.alias || [],
    redirect: pages_47_91_46_46_46permalink_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...permalink].vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta?.name ?? "server-@account-status",
    path: pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta?.path ?? "/:server?/@:account/:status",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47_91status_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/[status].vue").then(m => m.default || m)
  },
  {
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta?.path ?? "/:server?/@:account",
    children: [
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta?.name ?? "server-@account-index-followers",
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta?.path ?? "followers",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_47followers_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index/followers.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta?.name ?? "server-@account-index-following",
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta?.path ?? "following",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_47following_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index/following.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta?.name ?? "server-@account-index",
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta?.path ?? "",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta?.name ?? "server-@account-index-media",
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta?.path ?? "media",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_47media_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index/media.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta?.name ?? "server-@account-index-with_replies",
    path: pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta?.path ?? "with_replies",
    children: [],
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_47with_replies_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index/with_replies.vue").then(m => m.default || m)
  }
],
    name: pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta?.name ?? undefined,
    meta: pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47_64_91account_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/@[account]/index.vue").then(m => m.default || m)
  },
  {
    path: pages_47_91_91server_93_93_47explore_46vueMeta?.path ?? "/:server?/explore",
    children: [
  {
    name: pages_47_91_91server_93_93_47explore_47index_46vueMeta?.name ?? "server-explore",
    path: pages_47_91_91server_93_93_47explore_47index_46vueMeta?.path ?? "",
    children: [],
    meta: pages_47_91_91server_93_93_47explore_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47explore_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47explore_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/explore/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47explore_47links_46vueMeta?.name ?? "server-explore-links",
    path: pages_47_91_91server_93_93_47explore_47links_46vueMeta?.path ?? "links",
    children: [],
    meta: pages_47_91_91server_93_93_47explore_47links_46vueMeta,
    alias: pages_47_91_91server_93_93_47explore_47links_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47explore_47links_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/explore/links.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47explore_47tags_46vueMeta?.name ?? "server-explore-tags",
    path: pages_47_91_91server_93_93_47explore_47tags_46vueMeta?.path ?? "tags",
    children: [],
    meta: pages_47_91_91server_93_93_47explore_47tags_46vueMeta,
    alias: pages_47_91_91server_93_93_47explore_47tags_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47explore_47tags_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/explore/tags.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47explore_47users_46vueMeta?.name ?? "server-explore-users",
    path: pages_47_91_91server_93_93_47explore_47users_46vueMeta?.path ?? "users",
    children: [],
    meta: pages_47_91_91server_93_93_47explore_47users_46vueMeta,
    alias: pages_47_91_91server_93_93_47explore_47users_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47explore_47users_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/explore/users.vue").then(m => m.default || m)
  }
],
    name: pages_47_91_91server_93_93_47explore_46vueMeta?.name ?? undefined,
    meta: pages_47_91_91server_93_93_47explore_46vueMeta,
    alias: pages_47_91_91server_93_93_47explore_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47explore_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/explore.vue").then(m => m.default || m)
  },
  {
    path: pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta?.path ?? "/:server?/list/:list",
    children: [
  {
    name: pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta?.name ?? "server-list-list-index-accounts",
    path: pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta?.path ?? "accounts",
    children: [],
    meta: pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta,
    alias: pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47list_47_91list_93_47index_47accounts_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/list/[list]/index/accounts.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta?.name ?? "server-list-list-index",
    path: pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta?.path ?? "",
    children: [],
    meta: pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47list_47_91list_93_47index_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/list/[list]/index/index.vue").then(m => m.default || m)
  }
],
    name: pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta?.name ?? undefined,
    meta: pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47list_47_91list_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/list/[list]/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47lists_46vueMeta?.name ?? "server-lists",
    path: pages_47_91_91server_93_93_47lists_46vueMeta?.path ?? "/:server?/lists",
    children: [],
    meta: pages_47_91_91server_93_93_47lists_46vueMeta,
    alias: pages_47_91_91server_93_93_47lists_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47lists_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/lists.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47public_47index_46vueMeta?.name ?? "server-public",
    path: pages_47_91_91server_93_93_47public_47index_46vueMeta?.path ?? "/:server?/public",
    children: [],
    meta: pages_47_91_91server_93_93_47public_47index_46vueMeta,
    alias: pages_47_91_91server_93_93_47public_47index_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47public_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/public/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47public_47local_46vueMeta?.name ?? "server-public-local",
    path: pages_47_91_91server_93_93_47public_47local_46vueMeta?.path ?? "/:server?/public/local",
    children: [],
    meta: pages_47_91_91server_93_93_47public_47local_46vueMeta,
    alias: pages_47_91_91server_93_93_47public_47local_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47public_47local_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/public/local.vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta?.name ?? "server-status-status",
    path: pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta?.path ?? "/:server?/status/:status",
    children: [],
    meta: pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta,
    alias: pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47status_47_91status_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/status/[status].vue").then(m => m.default || m)
  },
  {
    name: pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta?.name ?? "server-tags-tag",
    path: pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta?.path ?? "/:server?/tags/:tag",
    children: [],
    meta: pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta,
    alias: pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta?.alias || [],
    redirect: pages_47_91_91server_93_93_47tags_47_91tag_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[server]]/tags/[tag].vue").then(m => m.default || m)
  },
  {
    name: pages_47blocks_46vueMeta?.name ?? "blocks",
    path: pages_47blocks_46vueMeta?.path ?? "/blocks",
    children: [],
    meta: pages_47blocks_46vueMeta,
    alias: pages_47blocks_46vueMeta?.alias || [],
    redirect: pages_47blocks_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/blocks.vue").then(m => m.default || m)
  },
  {
    name: pages_47bookmarks_46vueMeta?.name ?? "bookmarks",
    path: pages_47bookmarks_46vueMeta?.path ?? "/bookmarks",
    children: [],
    meta: pages_47bookmarks_46vueMeta,
    alias: pages_47bookmarks_46vueMeta?.alias || [],
    redirect: pages_47bookmarks_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/bookmarks.vue").then(m => m.default || m)
  },
  {
    name: pages_47compose_46vueMeta?.name ?? "compose",
    path: pages_47compose_46vueMeta?.path ?? "/compose",
    children: [],
    meta: pages_47compose_46vueMeta,
    alias: pages_47compose_46vueMeta?.alias || [],
    redirect: pages_47compose_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/compose.vue").then(m => m.default || m)
  },
  {
    name: pages_47conversations_46vueMeta?.name ?? "conversations",
    path: pages_47conversations_46vueMeta?.path ?? "/conversations",
    children: [],
    meta: pages_47conversations_46vueMeta,
    alias: pages_47conversations_46vueMeta?.alias || [],
    redirect: pages_47conversations_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conversations.vue").then(m => m.default || m)
  },
  {
    name: pages_47domain_blocks_46vueMeta?.name ?? "domain_blocks",
    path: pages_47domain_blocks_46vueMeta?.path ?? "/domain_blocks",
    children: [],
    meta: pages_47domain_blocks_46vueMeta,
    alias: pages_47domain_blocks_46vueMeta?.alias || [],
    redirect: pages_47domain_blocks_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/domain_blocks.vue").then(m => m.default || m)
  },
  {
    name: pages_47favourites_46vueMeta?.name ?? "favourites",
    path: pages_47favourites_46vueMeta?.path ?? "/favourites",
    children: [],
    meta: pages_47favourites_46vueMeta,
    alias: pages_47favourites_46vueMeta?.alias || [],
    redirect: pages_47favourites_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/favourites.vue").then(m => m.default || m)
  },
  {
    name: pages_47home_46vueMeta?.name ?? "home",
    path: pages_47home_46vueMeta?.path ?? "/home",
    children: [],
    meta: pages_47home_46vueMeta,
    alias: pages_47home_46vueMeta?.alias || [],
    redirect: pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: pages_47index_46vueMeta?.name ?? "index",
    path: pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: pages_47index_46vueMeta,
    alias: pages_47index_46vueMeta?.alias || [],
    redirect: pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47intent_47post_46vueMeta?.name ?? "intent-post",
    path: pages_47intent_47post_46vueMeta?.path ?? "/intent/post",
    children: [],
    meta: pages_47intent_47post_46vueMeta,
    alias: pages_47intent_47post_46vueMeta?.alias || [],
    redirect: pages_47intent_47post_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/intent/post.vue").then(m => m.default || m)
  },
  {
    name: pages_47mutes_46vueMeta?.name ?? "mutes",
    path: pages_47mutes_46vueMeta?.path ?? "/mutes",
    children: [],
    meta: pages_47mutes_46vueMeta,
    alias: pages_47mutes_46vueMeta?.alias || [],
    redirect: pages_47mutes_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mutes.vue").then(m => m.default || m)
  },
  {
    path: pages_47notifications_46vueMeta?.path ?? "/notifications",
    children: [
  {
    name: pages_47notifications_47index_46vueMeta?.name ?? "notifications",
    path: pages_47notifications_47index_46vueMeta?.path ?? "",
    children: [],
    meta: pages_47notifications_47index_46vueMeta,
    alias: pages_47notifications_47index_46vueMeta?.alias || [],
    redirect: pages_47notifications_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47notifications_47mention_46vueMeta?.name ?? "notifications-mention",
    path: pages_47notifications_47mention_46vueMeta?.path ?? "mention",
    children: [],
    meta: pages_47notifications_47mention_46vueMeta,
    alias: pages_47notifications_47mention_46vueMeta?.alias || [],
    redirect: pages_47notifications_47mention_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/notifications/mention.vue").then(m => m.default || m)
  }
],
    name: pages_47notifications_46vueMeta?.name ?? undefined,
    meta: pages_47notifications_46vueMeta,
    alias: pages_47notifications_46vueMeta?.alias || [],
    redirect: pages_47notifications_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: pages_47pinned_46vueMeta?.name ?? "pinned",
    path: pages_47pinned_46vueMeta?.path ?? "/pinned",
    children: [],
    meta: pages_47pinned_46vueMeta,
    alias: pages_47pinned_46vueMeta?.alias || [],
    redirect: pages_47pinned_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pinned.vue").then(m => m.default || m)
  },
  {
    path: pages_47settings_46vueMeta?.path ?? "/settings",
    children: [
  {
    name: pages_47settings_47about_47index_46vueMeta?.name ?? "settings-about",
    path: pages_47settings_47about_47index_46vueMeta?.path ?? "about",
    children: [],
    meta: pages_47settings_47about_47index_46vueMeta,
    alias: pages_47settings_47about_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47about_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/about/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47index_46vueMeta?.name ?? "settings",
    path: pages_47settings_47index_46vueMeta?.path ?? "",
    children: [],
    meta: pages_47settings_47index_46vueMeta,
    alias: pages_47settings_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47interface_47index_46vueMeta?.name ?? "settings-interface",
    path: pages_47settings_47interface_47index_46vueMeta?.path ?? "interface",
    children: [],
    meta: pages_47settings_47interface_47index_46vueMeta,
    alias: pages_47settings_47interface_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47interface_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/interface/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47language_47index_46vueMeta?.name ?? "settings-language",
    path: pages_47settings_47language_47index_46vueMeta?.path ?? "language",
    children: [],
    meta: pages_47settings_47language_47index_46vueMeta,
    alias: pages_47settings_47language_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47language_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/language/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47notifications_47index_46vueMeta?.name ?? "settings-notifications",
    path: pages_47settings_47notifications_47index_46vueMeta?.path ?? "notifications",
    children: [],
    meta: pages_47settings_47notifications_47index_46vueMeta,
    alias: pages_47settings_47notifications_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47notifications_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47notifications_47notifications_46vueMeta?.name ?? "settings-notifications-notifications",
    path: pages_47settings_47notifications_47notifications_46vueMeta?.path ?? "notifications/notifications",
    children: [],
    meta: pages_47settings_47notifications_47notifications_46vueMeta,
    alias: pages_47settings_47notifications_47notifications_46vueMeta?.alias || [],
    redirect: pages_47settings_47notifications_47notifications_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/notifications/notifications.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47notifications_47push_45notifications_46vueMeta?.name ?? "settings-notifications-push-notifications",
    path: pages_47settings_47notifications_47push_45notifications_46vueMeta?.path ?? "notifications/push-notifications",
    children: [],
    meta: pages_47settings_47notifications_47push_45notifications_46vueMeta,
    alias: pages_47settings_47notifications_47push_45notifications_46vueMeta?.alias || [],
    redirect: pages_47settings_47notifications_47push_45notifications_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/notifications/push-notifications.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47preferences_47index_46vueMeta?.name ?? "settings-preferences",
    path: pages_47settings_47preferences_47index_46vueMeta?.path ?? "preferences",
    children: [],
    meta: pages_47settings_47preferences_47index_46vueMeta,
    alias: pages_47settings_47preferences_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47preferences_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47profile_47appearance_46vueMeta?.name ?? "settings-profile-appearance",
    path: pages_47settings_47profile_47appearance_46vueMeta?.path ?? "profile/appearance",
    children: [],
    meta: pages_47settings_47profile_47appearance_46vueMeta,
    alias: pages_47settings_47profile_47appearance_46vueMeta?.alias || [],
    redirect: pages_47settings_47profile_47appearance_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/profile/appearance.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47profile_47featured_45tags_46vueMeta?.name ?? "settings-profile-featured-tags",
    path: pages_47settings_47profile_47featured_45tags_46vueMeta?.path ?? "profile/featured-tags",
    children: [],
    meta: pages_47settings_47profile_47featured_45tags_46vueMeta,
    alias: pages_47settings_47profile_47featured_45tags_46vueMeta?.alias || [],
    redirect: pages_47settings_47profile_47featured_45tags_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/profile/featured-tags.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47profile_47index_46vueMeta?.name ?? "settings-profile",
    path: pages_47settings_47profile_47index_46vueMeta?.path ?? "profile",
    children: [],
    meta: pages_47settings_47profile_47index_46vueMeta,
    alias: pages_47settings_47profile_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47profile_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47settings_47users_47index_46vueMeta?.name ?? "settings-users",
    path: pages_47settings_47users_47index_46vueMeta?.path ?? "users",
    children: [],
    meta: pages_47settings_47users_47index_46vueMeta,
    alias: pages_47settings_47users_47index_46vueMeta?.alias || [],
    redirect: pages_47settings_47users_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/users/index.vue").then(m => m.default || m)
  }
],
    name: pages_47settings_46vueMeta?.name ?? undefined,
    meta: pages_47settings_46vueMeta,
    alias: pages_47settings_46vueMeta?.alias || [],
    redirect: pages_47settings_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: pages_47share_45target_46vueMeta?.name ?? "share-target",
    path: pages_47share_45target_46vueMeta?.path ?? "/share-target",
    children: [],
    meta: pages_47share_45target_46vueMeta,
    alias: pages_47share_45target_46vueMeta?.alias || [],
    redirect: pages_47share_45target_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/share-target.vue").then(m => m.default || m)
  }
]