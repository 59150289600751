export const appKeepalive = true

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,viewport-fit=cover"},{"charset":"utf-8"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"property":"og:title","content":"Elk"},{"property":"og:description","content":"A nimble Mastodon web client"},{"property":"og:type","content":"website"},{"property":"og:image","content":"https://elk.zone/elk-og.png"},{"property":"og:image:width","content":"3800"},{"property":"og:image:height","content":"1900"},{"property":"og:site_name","content":"Elk"},{"property":"twitter:site","content":"@elk_zone"},{"property":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","type":"image/svg+xml","href":"/logo.svg"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"viewport":"width=device-width,initial-scale=1,viewport-fit=cover","bodyAttrs":{"class":"overflow-x-hidden"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"