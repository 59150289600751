import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.1.1_7rz7g5sqfnn6wuv5lem37retty/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import _1_45permalink_45global from "/vercel/path0/middleware/1.permalink.global.ts";
import _2_45single_45instance_45global from "/vercel/path0/middleware/2.single-instance.global.ts";
export const globalMiddleware = [
  validate,
  _1_45permalink_45global,
  _2_45single_45instance_45global
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts")
}