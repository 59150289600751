
import { defuFn } from '/vercel/path0/node_modules/.pnpm/defu@6.1.2/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "storage": {
    "driver": "cloudflare"
  },
  "env": "release",
  "buildInfo": {
    "version": "0.7.2",
    "time": 1675938077731,
    "commit": "c8085a8efa8fce0ce8e1a943853af9f3925c580e",
    "branch": "master",
    "env": "release"
  },
  "pwaEnabled": true
}



export default defuFn(inlineConfig)
