import node_modules__pnpm__64pinia_43nuxt_640_4_6_typescript_644_9_5_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_fLrqqKt2dI from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.6_typescript@4.9.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_fuzgtEqehL from "/vercel/path0/node_modules/.pnpm/nuxt@3.1.1_7rz7g5sqfnn6wuv5lem37retty/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_LPnezskeIe from "/vercel/path0/node_modules/.pnpm/nuxt@3.1.1_7rz7g5sqfnn6wuv5lem37retty/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_Ro5lttkX7J from "/vercel/path0/node_modules/.pnpm/nuxt@3.1.1_7rz7g5sqfnn6wuv5lem37retty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import node_modules__pnpm__64nuxtjs_43color_mode_643_2_0_node_modules__64nuxtjs_color_mode_dist_runtime_plugin_client_mjs_oeNHLp3vL0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.2.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import node_modules__pnpm__64nuxtjs_43i18n_648_0_0_beta_9_node_modules__64nuxtjs_i18n_dist_runtime_plugins_composition_mjs_lNeP6AFiLC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import node_modules__pnpm__64nuxtjs_43i18n_648_0_0_beta_9_node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_zspMjnrUIl from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _nuxt_unocss_mjs_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import plugins_color_mode_ts_Tkxaf6OtwV from "/vercel/path0/plugins/color-mode.ts";
import plugins_floating_vue_ts_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import plugins_hydration_client_ts_hLwk9zs2YO from "/vercel/path0/plugins/hydration.client.ts";
import plugins_masto_ts_XvM57Hn2Y4 from "/vercel/path0/plugins/masto.ts";
import plugins_page_lifecycle_client_ts_YRsLvdfrQK from "/vercel/path0/plugins/page-lifecycle.client.ts";
import plugins_pwa_client_ts_PLDhtd2phb from "/vercel/path0/plugins/pwa.client.ts";
import plugins_scroll_to_top_ts_gyAlBetrpL from "/vercel/path0/plugins/scroll-to-top.ts";
import plugins_setup_global_effects_client_ts_APfPTu8sO9 from "/vercel/path0/plugins/setup-global-effects.client.ts";
import plugins_setup_i18n_client_ts_uDruhrtO4P from "/vercel/path0/plugins/setup-i18n.client.ts";
export default [
  node_modules__pnpm__64pinia_43nuxt_640_4_6_typescript_644_9_5_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_fLrqqKt2dI,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_fuzgtEqehL,
  node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_LPnezskeIe,
  node_modules__pnpm_nuxt_643_1_1_7rz7g5sqfnn6wuv5lem37retty_node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_Ro5lttkX7J,
  node_modules__pnpm__64nuxtjs_43color_mode_643_2_0_node_modules__64nuxtjs_color_mode_dist_runtime_plugin_client_mjs_oeNHLp3vL0,
  node_modules__pnpm__64nuxtjs_43i18n_648_0_0_beta_9_node_modules__64nuxtjs_i18n_dist_runtime_plugins_composition_mjs_lNeP6AFiLC,
  node_modules__pnpm__64nuxtjs_43i18n_648_0_0_beta_9_node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_zspMjnrUIl,
  _nuxt_unocss_mjs_MzCDxu9LMj,
  plugins_color_mode_ts_Tkxaf6OtwV,
  plugins_floating_vue_ts_BiOD74u9sH,
  plugins_hydration_client_ts_hLwk9zs2YO,
  plugins_masto_ts_XvM57Hn2Y4,
  plugins_page_lifecycle_client_ts_YRsLvdfrQK,
  plugins_pwa_client_ts_PLDhtd2phb,
  plugins_scroll_to_top_ts_gyAlBetrpL,
  plugins_setup_global_effects_client_ts_APfPTu8sO9,
  plugins_setup_i18n_client_ts_uDruhrtO4P
]